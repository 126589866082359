import React, { useState } from 'react';
import './LeadCaptureForm.css';
import SendIcon from '../../assets/svgs/SendIcon';

interface LeadCaptureFormProps {
  onSubmit: (name: string, email: string, phone: string, message: string) => Promise<void>;
  onClose: () => void;
  conversationHistory: string;
}

interface ConversationEntry {
  isUser: boolean;
  text: string;
}

const LeadCaptureForm: React.FC<LeadCaptureFormProps> = ({ onSubmit, onClose, conversationHistory }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<{ name?: string; email?: string }>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const session_id = localStorage.getItem('session_id');

    // Reset errors
    setErrors({});

    // Validate form fields
    const newErrors: { name?: string; email?: string } = {};
    if (!name) {
      newErrors.name = 'Name is required*';
    }
    if (!email) {
      newErrors.email = 'Email is required*';
    }

    // If there are errors, set them and prevent submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Format conversation history
    const formattedHistory: ConversationEntry[] = conversationHistory
      .split('\n')
      .map(line => {
        const [user, ...textParts] = line.split(': ');
        return {
          isUser: user.trim() === 'User',
          text: textParts.join(': ').trim()
        };
      })
      .filter(entry => entry.text !== "Contact Us!");

    try {
      const response = await fetch('/api/submit-lead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session_id}`
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          message,
          conversation_history: formattedHistory
        }),
        credentials: 'include'
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to submit lead');
      }

      await onSubmit(name, email, phone, message);
      onClose();
    } catch (error) {
      console.error('Error submitting lead:', error);
      alert(error instanceof Error ? error.message : 'Failed to submit lead. Please try again.');
    }
  };

  return (
    <div className="lead-capture-form">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          {errors.name ? (
            <span className="error-message">{errors.name}</span>
          ) : (
            <label htmlFor="name">Name *</label>
          )}
          <input
            id="name"
            type="text"
            placeholder="Enter your name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          {errors.email ? (
            <span className="error-message">{errors.email}</span>
          ) : (
            <label htmlFor="email">Email *</label>
          )}
          <input
            id="email"
            type="email"
            placeholder="Enter your email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            type="tel"
            placeholder="Enter your phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="form-buttons">
          <button className="submit-btn" type="button" onClick={handleSubmit}>
            Send
            <SendIcon />
          </button>
        </div>
      </form>
      <button onClick={onClose} className="close-btn" aria-label="Close"></button>
    </div>
  );
};

export default LeadCaptureForm;
