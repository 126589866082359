import React from 'react';
import './Message.css';

import { formatText } from '../../lib/helpers/makeLinksClickable';

interface MessageProps {
    user: string;
    text: string;
}



const Message = ({ user, text }: MessageProps) => {
    return (
        <div className={`message ${user === 'Assistant' ? 'assistant-message' : 'user-message'}`}>
            <p className="message-text">{user === 'Assistant' ? formatText(text) : text}</p>
        </div>
    );
};


export default Message;
