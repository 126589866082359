// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refresh-icon {
    transition: transform 0.4s ease; /* Smooth transition for the rotation */
}

.refresh-icon:hover {
    transform: rotate(180deg); /* Rotate 180 degrees on hover */
}

`, "",{"version":3,"sources":["webpack://./src/assets/svgs/RefreshIcon.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B,EAAE,uCAAuC;AAC5E;;AAEA;IACI,yBAAyB,EAAE,gCAAgC;AAC/D","sourcesContent":[".refresh-icon {\n    transition: transform 0.4s ease; /* Smooth transition for the rotation */\n}\n\n.refresh-icon:hover {\n    transform: rotate(180deg); /* Rotate 180 degrees on hover */\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
