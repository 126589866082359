import React, { createContext, useState, useEffect, useCallback } from "react";

import { useLocation } from 'react-router-dom'

interface ChatbotContextType {
    image_url: string;
    name: string;
    preloaded_messages: string[];
    suggested_prompts: string[];
    session_id: string;
    color: string;
    slug: string;
}

const initialContext: ChatbotContextType = {
    image_url: "",
    name: "",
    preloaded_messages: [],
    suggested_prompts: [],
    session_id: "",
    color: "",
    slug: ""
};

export const ChatbotContext = createContext(initialContext);
const ChatbotDispatchContext = createContext({});

export const ChatbotProvider = ({ children, slug, domain }: { children: any; slug?: string; domain?: string; }) => {
  const [context, setContext] = useState(initialContext);

  const params = useLocation().pathname.split('/').filter(Boolean);

    const fetchAssistant = useCallback(async ({domain, assistant_id}: {domain: string, assistant_id: string}) => {
        //send domain to backend for verification
        const response = await fetch(`${domain || ''}/api/assistant/${assistant_id}`,{
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        });
        const data = await response.json() as ChatbotContextType;

        return data;
    }, []);


    const assistant_id = slug || params[1]; 

    useEffect(() => {
        let mounted = true;

        const initializeAssistant = async () => {
                try {
                    const data = await fetchAssistant({domain: domain || '', assistant_id: assistant_id || ''});
                    if (mounted && data) {

                        setContext(data);
                        localStorage.setItem('session_id', data.session_id);

                    }
                } catch (error) {
                    console.error("Failed to initialize assistant:", error);
                    // Handle initialization failure (e.g., show error message)
                }
        };

        initializeAssistant();

        return () => {
            mounted = false;
        };
    }, [assistant_id, domain, fetchAssistant]);

    return (
        <ChatbotContext.Provider value={context}>
            <ChatbotDispatchContext.Provider value={setContext}>
                {children}
            </ChatbotDispatchContext.Provider>
        </ChatbotContext.Provider>
    );
};
