import React, { useState, useRef, useEffect } from 'react';
import './InputField.css';
import SendIcon from '../../assets/svgs/SendIcon';

interface InputFieldProps {
    onSendMessage: (message: string) => void;
    disabled?: boolean;
}

const InputField = ({ onSendMessage, disabled = false }: InputFieldProps) => {
    const [input, setInput] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus();
        }
    }, [disabled]);

    const onInputChange = (e: any) => {
        setInput(e.target.value);
    };

    const onSend = () => {
        if (input.trim()) { // Checks if the input is not just white spaces
            onSendMessage(input); // Sends the message to the parent component
            setInput(''); // Clears the input field
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend();
        }
    };

    return (
        <div className="input-field-container">
            <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={onInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Message..."
                className="input-field"
                aria-label="Type a message"
                disabled={disabled}
            />
            <button 
                type="button"
                onClick={onSend} 
                aria-label="Send message"
            >
                <SendIcon />
            </button>

        </div>
    );
};

export default InputField;
