import React from 'react';
import './Pill.css'


interface PillProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
}

export default function Pill({ text, onClick, disabled }: PillProps) {
    return (
        <div>
            <button 
                className='pill-container'
                onClick={onClick}
                disabled={disabled}
            >
                    {text}
            </button>
        </div>
    )
}