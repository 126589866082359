// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #BCBCBC;
    color: #BCBCBC;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #BCBCBC;
    color: #BCBCBC;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #BCBCBC;
    color: #BCBCBC;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #BCBCBC;
    }
    50%, 100% {
      background-color: rgba(188, 188, 188, 0.2);;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    background-color: #f1f1f3; /* iMessage light gray */;
    border-radius: 10px;
    padding: 1rem 1.25rem;
    margin: .75rem 0;
    width: 50px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,oDAAoD;IACpD,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,MAAM;EACR;EACA;IACE,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,6CAA6C;IAC7C,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,6CAA6C;IAC7C,mBAAmB;EACrB;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,0CAA0C;IAC5C;EACF;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,yBAAyB,EAAE,wBAAwB;IACnD,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".dot-flashing {\n    position: relative;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #BCBCBC;\n    color: #BCBCBC;\n    animation: dot-flashing 1s infinite linear alternate;\n    animation-delay: 0.5s;\n  }\n  .dot-flashing::before, .dot-flashing::after {\n    content: \"\";\n    display: inline-block;\n    position: absolute;\n    top: 0;\n  }\n  .dot-flashing::before {\n    left: -15px;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #BCBCBC;\n    color: #BCBCBC;\n    animation: dot-flashing 1s infinite alternate;\n    animation-delay: 0s;\n  }\n  .dot-flashing::after {\n    left: 15px;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #BCBCBC;\n    color: #BCBCBC;\n    animation: dot-flashing 1s infinite alternate;\n    animation-delay: 1s;\n  }\n  \n  @keyframes dot-flashing {\n    0% {\n      background-color: #BCBCBC;\n    }\n    50%, 100% {\n      background-color: rgba(188, 188, 188, 0.2);;\n    }\n  }\n\n  .loading-container {\n    display: flex;\n    justify-content: center;\n    background-color: #f1f1f3; /* iMessage light gray */;\n    border-radius: 10px;\n    padding: 1rem 1.25rem;\n    margin: .75rem 0;\n    width: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
