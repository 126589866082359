
import './Tooltip.css';

export default function Tooltip({ children, text }: { children: React.ReactNode, text: string }) {
  return <div className="tooltip-container">
    <div>
      {children}
      <div className="tooltip-text">
        {text}
      </div>
    </div>

  </div>;
}