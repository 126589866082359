// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-container {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f3f3f2;
}

.input-field {
    width: 95%;
    padding: 0.7rem;
    border-radius: 5px;
    padding: 1rem 0.75rem;
    border: none;
    outline: none;
    height: 36px;
    font-size: medium;
}

.input-field:disabled {
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/InputField.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".input-field-container {\n    display: flex;\n    justify-content: space-between;\n    border-top: 2px solid #f3f3f2;\n}\n\n.input-field {\n    width: 95%;\n    padding: 0.7rem;\n    border-radius: 5px;\n    padding: 1rem 0.75rem;\n    border: none;\n    outline: none;\n    height: 36px;\n    font-size: medium;\n}\n\n.input-field:disabled {\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
