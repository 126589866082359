import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Your main React component


const config = window.embeddedChatbotConfig || {};

  const container = document.createElement('div');
  container.id = 'aii-embed-container';
  document.body.appendChild(container);
  
  ReactDOM.render(<App config={config} chatbot={true} />, document.getElementById('aii-embed-container'));

