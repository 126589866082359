import React, { useState, useEffect, useRef, useContext, useCallback, lazy, Suspense } from 'react';
import Message from './Message';
import InputField from './InputField';
import Header from './Header';
import Pill from '../Pill/Pill';
import { ChatbotContext } from '../../context/ChatbotContext';
import Tooltip from '../Tooltip/Tooltip';

import './ChatWindow.css';
import Loading from '../Loading/Loading';

const LazyLeadCaptureForm = lazy(() => import('../LeadCaptureForm/LeadCaptureForm'));

function ChatWindow() {

    const [messages, setMessages] = useState<{ user: string, text: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentStreamMessage, setCurrentStreamMessage] = useState<string>(''); // State to hold the current streaming message
    const [showLeadForm, setShowLeadForm] = useState(false);

    const assistant = useContext(ChatbotContext);

    const sendMessage = async (text: string) => {
        setLoading(true);
        setMessages(messages => [...messages, { user: "User", text }]);
        setShowLeadForm(false); // Hide the lead form when sending a message
        const session_id = localStorage.getItem('session_id');
        const response = await fetch("/api/chat-stream", {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${session_id}` },
            body: JSON.stringify({ message: text }),
            credentials: "include",
        });

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder("utf-8");

        if (!reader) {
            console.log('no reader found');
            setLoading(false);
            return;
        }

        let buffer = '';
        let completeMessage = ''; // Variable to accumulate the complete message
        let firstDataReceived = false;

        while (true) {

            const { done, value } = await reader.read();
            if (done) break;

            buffer += decoder.decode(value, { stream: true });

            let lines = buffer.split('\n');
            //@ts-ignore
            buffer = lines.pop(); // Save the last line as it might be incomplete

            for (let line of lines) {
                if (line.startsWith('data: ')) {
                    const subString = line.substring(6); // Remove 'data: ' prefix
                    completeMessage += subString
                    setCurrentStreamMessage(completeMessage);

                    if (!firstDataReceived) {
                        setLoading(false);
                        firstDataReceived = true;
                    }
                }
            }
        }

        // Once the stream is complete, add the complete message to the messages array
        setMessages(prev => [...prev, { user: "Assistant", text: completeMessage }]);
        setCurrentStreamMessage(''); // Clear the current streaming message
        setLoading(false);
    };

    // Function to handle resetting the chat window
    const resetChat = useCallback(() => {
        setMessages(
            assistant.preloaded_messages.map((message: string) => {
                return { user: "Assistant", text: message }
            })
        );
        setShowLeadForm(false); // Close lead form when resetting chat
    }, [assistant.preloaded_messages]);

    useEffect(() => {
        if (!assistant) return;
        resetChat();
    }, [assistant, resetChat]);

    const bottomRef = useRef<HTMLDivElement>(null);
    const leadFormRef = useRef<HTMLDivElement>(null);
    const messagesListRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = useCallback(() => {
        if (messagesListRef.current) {
            messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages, currentStreamMessage, scrollToBottom]); // Scroll when messages or currentStreamMessage change

    useEffect(() => {
        if (showLeadForm) {
            leadFormRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [showLeadForm]);

    const handleCloseChatbot = () => {
        try {
            window.parent.postMessage("closeChatbot", "*");
        } catch (error) {
            console.error("Error sending message to parent:", error);
        }
    }

    const handleLeadFormToggle = () => {
        setShowLeadForm(prev => !prev);
    };

    const handleLeadFormSubmit = async (name: string, email: string, phone: string, message: string) => {
        try {
            setShowLeadForm(false);
            setMessages(prev => [...prev, {
                user: "Assistant",
                text: `Thank you for contacting ${assistant.name}! How else can I assist you today?`
            }]);
        } catch (error) {
            console.error('Error handling lead form submission:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <div className="chat-window">
            <Header onResetChat={resetChat} onExitChat={handleCloseChatbot} />
            <div className="messages-list" ref={messagesListRef}>
                {messages.map((message, index) => (
                    <Message key={index} user={message.user} text={message.text} />
                ))}
                {currentStreamMessage && (
                    <Message user="Assistant" text={currentStreamMessage} />
                )}
                {loading && <div><Loading /></div>}
                {showLeadForm && (
                    <div ref={leadFormRef}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <LazyLeadCaptureForm
                                onSubmit={handleLeadFormSubmit}
                                onClose={() => setShowLeadForm(false)}
                                conversationHistory={messages.map(m => `${m.user}: ${m.text}`).join('\n')}
                            />
                        </Suspense>
                    </div>
                )}
                <div ref={bottomRef} />
            </div>

            <div className='inputs-container'>
                <div className='suggestions-container' style={{ display: 'flex', alignItems: 'bottom' }}>

                    <div className='suggestions'>
                        <Tooltip text={`Contact ${assistant.name}`}>
                            <button
                                className="lead-form-button"
                                onClick={handleLeadFormToggle}
                                aria-label="Toggle lead capture form"
                                title={`Contact ${assistant.name}`}
                            >
                                👋
                            </button>
                        </Tooltip>
                        {assistant.suggested_prompts.map((prompt, index) => (
                            <Pill key={index} text={prompt} onClick={() => sendMessage(prompt)} disabled={loading} />
                        ))}
                    </div>
                </div>
                <InputField onSendMessage={sendMessage} disabled={loading} />
                <div className='powered-by'>
                    <span>
                        Powered by{' '}
                        <a href='https://aiintegrations.tech/' target='_blank' rel="noreferrer" className="ai-integrations-link">
                            <span className="underline">AI Integrations</span>™
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ChatWindow;
