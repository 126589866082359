import React from 'react';
import { ChatbotProvider } from './context/ChatbotContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ChatWindow from './components/ChatWindow/ChatWindow';
import Chatbot from './components/Chatbot/Chatbot';
import './App.css';

interface AppConfig {
  assistant_slug: string;
  domain: string;

}

function App({ chatbot, config }: { chatbot: boolean; config: AppConfig } ) {
  if (chatbot && config?.assistant_slug && config?.domain) {
    return (
      <div>
        <BrowserRouter>
          <ChatbotProvider slug={config.assistant_slug} domain={config.domain}  >
            <Chatbot />
          </ChatbotProvider>
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ChatbotProvider>
          <Routes>
            <Route path="/full/:assistant" element={<ChatWindow />} />
            <Route path="/bot/:assistant" element={<Chatbot />} />
          </Routes>
        </ChatbotProvider>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
